import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Box, Typography, makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import LockIcon from '@mui/icons-material/Https';
import { getFilterSentence } from '../common/Common.service';
import { ROUTES } from '../../Route/Routes.constants';

const useStyles = makeStyles(() => ({
	container: {
		backgroundColor: '#FFFF8A',
		width: '100%',
		borderRadius: '8px',
		padding: '1rem',
		cursor: 'pointer',
	},
	makeCircle: {
		height: '70px',
		width: '70px',
		borderRadius: '50%',
		backgroundColor: '#7EC8E3',
	},
	learnContainer: {
		width: '260px',
		height: '270px',
		borderRadius: '8px',
		backgroundColor: '#FFF',
		cursor: 'pointer',
		boxShadow: `rgb(38, 57, 77) 0px 20px 30px -10px`,
	},
	learnTitle: {
		marginTop: '-8px',
		padding: '1rem 0.5rem',
		borderRadius: '0 0 8px 8px',
		textAlign: 'center',
		fontSize: '1.2rem',
		fontWeight: 700,
		marginTop: 'auto',
		marginBottom: 'auto',
	},
	teamContainer: {
		width: '280px',
		height: '300px',
		borderRadius: '8px',
		backgroundColor: '#FFF',
		boxShadow: `rgb(38, 57, 77) 0px 20px 30px -10px`,
		margin: '1rem auto',
	},
	nameStyle: {
		textAlign: 'center',
		fontSize: '1.5rem',
		fontWeight: '700',
		letterSpacing: '1px',
		color: '#0000FF',
		textTransform: 'uppercase',
	},
	positionStyle: {
		textAlign: 'center',
		fontSize: '1rem',
		fontWeight: '700',
		letterSpacing: '1px',
		color: '#D18700',
	},
	imgStyleForTeam: {
		width: '60%',
		height: '55%',
		borderRadius: '50%',
		margin: '1rem auto',
		marginLeft: '18%',
		backgroundColor: '#EAEFF2',
	},
	imgStyle: {
		width: '170px',
		borderRadius: '8px',
		"@media screen and (max-width: 900px)": {
		  width: '140px',
		},
		"@media screen and (max-width: 600px)": {
		  width: '100px',
		},
		"@media screen and (max-width: 450px)": {
		  width: '80px',
		},
	},
	unlistedContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		gap: '40px',
		width: '100%',
		overflow: 'auto',
		marginTop: "1rem",
		'&::-webkit-scrollbar': { 
			width: 0, 
		}
	},
}))

const BoxContainer = ({ _bondData = null, _mFData = null, _unlistedData = null, _learnAboutBondsData = null, _meetOurTeam = null }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [hover, setHover] = useState(false);
  const [filterUSData, setFilterUSData] = useState([]);
  const [countUS, setCountUS] = useState(1);

  const profileData = useSelector((state) => state.profile.login);

  let observerUS = useRef();

  const findLastElementKeyForUS = useCallback(node  => {
	if (observerUS.current) observerUS.current.disconnect();
	observerUS.current = new IntersectionObserver(entries => {
		if (entries[0].isIntersecting) {
			const arr = [..._unlistedData].slice(0, 8 * (countUS + 1));
			setFilterUSData(arr);
			setCountUS(prev => prev + 1);
		}
	});
	if (node) observerUS.current.observe(node);
  });

  const getKeyName = (val) => {
	let key = val.toLowerCase();
	if (key === 'aum') return 'Assets Under Management (AUM)';
	if (key === '1d') return '1 Day Return';
	if (key === '1w') return '1 Week Return';
	if (key === '1m') return '1 Month Return';
	if (key === '1y') return '1 Year Return';
	if (key === '3y') return '3 Year Return';
	if (key === '5y') return '5 Year Return';
	return val;
  }

  const getFilteredDataForMF = (key) => {
	let val = _mFData[key];
	if (key.toLowerCase() === 'name' || key.toLowerCase() === 'image') return null;
	return (
		<Box key={key} style={{ padding: key.toLowerCase() === 'aum' ? "1rem 0" : "0", marginTop: '0.5rem', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '10px' }}>
			<Typography style={{ color: '#808080', fontSize: '0.875rem', letterSpacing: '1px'  }}>
				{getKeyName(key)}
			</Typography>
			<Typography style={{ color: '#282828', fontSize: '1rem', letterSpacing: '1px', fontWeight: 700 }}>
				{val}{' '}{key.toLowerCase() === 'aum' ? 'Cr' : '%'}
			</Typography>
		</Box>
	)
  }

  const getFilteredDataForBonds = (key) => {
	let val = _bondData[key];
	if (key.toLowerCase() === 'name' || key.toLowerCase() === 'rating' || key.toLowerCase() === 'price' || key.toLowerCase() === 'ip') return null;
	return (
		<Box key={key} style={{ marginTop: '0.5rem', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '10px' }}>
			<Typography style={{ color: '#808080', fontSize: '0.875rem', letterSpacing: '1px'  }}>
				{key}
			</Typography>
			<Typography style={{ color: '#282828', fontSize: '1rem', letterSpacing: '1px', fontWeight: 700 }}>
				{
					key.toUpperCase() === 'YTM' && !profileData.phoneNumber ? (
						<Box style={{ cursor: 'pointer', display: 'flex' }} onClick={() => navigate(ROUTES.LOGIN)}>
							<Typography style={{ fontSize: '1rem', color: "#EC9B26", fontWeight: 700, }}>
								SignUp
							</Typography>
							<LockIcon style={{ color: "#EC9B26" }} />
						</Box>
					)  : getFilterSentence(val)
				}
			</Typography>
		</Box>
	)
  }

  const USListComponent = ({item, k}) => {
	return (
		<Box
			style={{ 
				transform: hover === k ? 'scale(1.05)' : 'scale(1)',
				margin: hover === k && '1rem',
				background: "#FFF",
				padding: "2rem",
				boxShadow: '0 0 4px 4px rgba(0,0,0,.25)',
			}}
			className={classes.container}
			onMouseEnter={() => setHover(k)}
			onMouseLeave={() => setHover(false)}
		>
			{
				Object.entries(item).map(([key, value]) => (
					<Box>
						<Box key={key} style={{ marginTop: '0.5rem', paddingBottom: '0.5rem', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '70px' }}>
							<Typography style={{ color: '#808080', fontSize: '0.875rem', letterSpacing: '1px', width: '100px'  }}>
								{key}
							</Typography>
							<Typography style={{ color: key === "Name" ? '#10BBE5' : '#282828', fontSize: '1rem', letterSpacing: '1px', fontWeight: 700 }}>
								{ key === 'Price' ? "Rs " : "" }{value}
							</Typography>
						</Box>
						{
							key === "Name" && <hr style={{ margin: '1.2rem 0 1.8rem 0' }} />
						}
					</Box>
				))
			}
		</Box>
	)
  }

  useEffect(() => {
	if (_unlistedData) {
		setFilterUSData([..._unlistedData].splice(0, 8));
	}
  }, [_unlistedData]);

  return (
	<Box>
		{
			_bondData && (
				<Box 
					style={{ 
						transform: hover ? 'scale(1.1)' : 'scale(1)',
						margin: hover && '1rem',
					}}
					className={classes.container} 
					onMouseEnter={() => setHover(true)}
					onMouseLeave={() => setHover(false)}
				>
					<Box 
						style = {{ 
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							gap: '50px'
						}}
					>
						<Typography style={{ margin: 'auto', color: '#0000D1', fontSize: '0.875rem', letterSpacing: '1px', fontWeight: 700, width: '200px' }}>
							{_bondData?.Name}
						</Typography>
						<Box className={classes.makeCircle}>
							<Typography style={{ textAlign: 'center', marginTop: _bondData?.Rating?.[1] ? '14px' : '20px', color: '#282828', fontSize: '1.2rem', letterSpacing: '1px', fontWeight: 700  }}>
								{_bondData?.Rating?.[0]}
							</Typography>
							<Typography style={{ textAlign: 'center', color: '#282828', fontSize: '0.5rem', letterSpacing: '1px', fontWeight: 700  }}>
								{_bondData?.Rating?.[1]}
							</Typography>
						</Box>
					</Box>
					<hr style={{ margin: '1.2rem 0 1.8rem 0' }} />
					{
						Object.keys(_bondData).map(key => (
							getFilteredDataForBonds(key)
						))
					}
				</Box>
			)
		}
		{
			_mFData && (
				<Box 
					style={{ 
						transform: hover ? 'scale(1.05)' : 'scale(1)',
						margin: hover && '1rem',
						background: "#F4EAE6",
					}}
					className={classes.container} 
					onMouseEnter={() => setHover(true)}
					onMouseLeave={() => setHover(false)}
				>
					<Box 
						style = {{ 
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							gap: '50px'
						}}
					>
						<img className={classes.imgStyle} src={_mFData?.Image} />
						<Typography style={{ margin: 'auto', color: '#0000D1', fontSize: '0.875rem', letterSpacing: '1px', fontWeight: 700, width: '200px' }}>
							{_mFData?.Name?.toUpperCase()}
						</Typography>
					</Box>
					<hr style={{ margin: '1.2rem 0 1.8rem 0' }} />
					{
						Object.keys(_mFData).map(key => (
							getFilteredDataForMF(key)
						))
					}
				</Box>
			)
		}
		<Box className={classes.unlistedContainer}>
			{
				filterUSData.length > 0 && filterUSData.map((item, k) => (
					k + 1 === filterUSData.length ? (
						<Box key={k} ref={findLastElementKeyForUS}>
							<USListComponent item={item} k={k} />
						</Box>
					): (
						<USListComponent item={item} k={k}  />
					)
					
				))
			}
		</Box>
		
		{
			_learnAboutBondsData && (
				<Box className={classes.learnContainer} onClick={() => navigate(_learnAboutBondsData.to)}>
					<img src={_learnAboutBondsData.src} style={{ borderRadius: '8px' }} width="100%" />
					<Box
						style={{
							color: hover && '#0000D1',
						}}
						onMouseEnter={() => setHover(true)}
						onMouseLeave={() => setHover(false)}
						className={classes.learnTitle}
					>
						{_learnAboutBondsData.title}
					</Box>
				</Box>
			)
		}
		{
			_meetOurTeam && (
				<Box className={classes.teamContainer}>
					<img className={classes.imgStyleForTeam} src={_meetOurTeam.src} />
					<Typography className={classes.nameStyle}>
						{_meetOurTeam.name}
					</Typography>
					<Typography className={classes.positionStyle}>
						{_meetOurTeam.position}
					</Typography>
				</Box>
			)
		}
	</Box>
  )
}

export default BoxContainer;
