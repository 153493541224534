import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Backdrop, Box, CircularProgress, Tooltip, Typography, makeStyles } from '@material-ui/core';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { useNavigate } from 'react-router-dom';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import BoxContainer from './BoxContainer.Layout';
import { ROUTES } from '../../Route/Routes.constants';

const useStyles = makeStyles(() => ({
	bondContainer: {
		backgroundColor: '#0C2D48',
		padding: '2rem',
		height: '27rem',
		'@media screen and (max-width: 899px)': {
			padding: '1.5rem',
		},
		'@media screen and (max-width: 500px)': {
			padding: '1rem',		
		},
	},
	bondTitle: {
		color: '#FFF',
		fontSize: '2.5rem',
		fontWeight: 700,
		marginBottom: '2rem',
		'@media screen and (max-width: 899px)': {
			fontSize: '2rem',
		},
		'@media screen and (max-width: 500px)': {
			fontSize: '1.5rem',	
		},
	},
	bondGridFormat: {
		position: 'absolute',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		gap: '5rem',
		overflowX: 'scroll',
		width: 'calc(100% - 4rem)',
		fontFamily: "Arial Rounded MT Bold",
		'@media screen and (max-width: 899px)': {
			width: 'calc(100% - 3rem)',
		},
		'@media screen and (max-width: 500px)': {
			width: 'calc(100% - 2rem)',
		},
		'&::-webkit-scrollbar': { 
			width: 0, 
		}
	},
	iconStyle: {
		color: '#00FF00',
		cursor: 'pointer',
		marginTop: '8px',
		'@media screen and (max-width: 899px)': {
			marginTop: '0',
		}
	},
	learnContainer: {
		backgroundColor: '#E2DED0',
		padding: '2rem',
		height: '23rem',
		'@media screen and (max-width: 899px)': {
			padding: '1.5rem',
		},
		'@media screen and (max-width: 500px)': {
			padding: '1rem',		
		},
	},
	
}));

const BoxSection = ({ bond = null, mf = null, unlisted = null, bondData = null, mFData = null, unlistedData = null, learnAboutBondsData = null, meetOurTeam = null }) => {

  const classes = useStyles();
  const navigate = useNavigate();

  const [newBondData, setNewBondData] = useState([]);
  const [filterBondData, setFilterBondData] = useState([]);
  const [newMFData, setNewMFData] = useState([]);
  const [filterMFData, setFilterMFData] = useState([]);
  const [countBond, setCountBond] = useState(1);
  const [countMF, setCountMF] = useState(1);

  const isEmpty = (obj) => {
	if (obj === null) return true;
    return Object.keys(obj).length === 0;
  }

  let observerBond = useRef();
  let observerMF = useRef();

  const findLastElementKeyForBond = useCallback(node  => {
	if (observerBond.current) observerBond.current.disconnect();
	observerBond.current = new IntersectionObserver(entries => {
		if (entries[0].isIntersecting) {
			const arr = newBondData.slice(0, 8 * (countBond + 1));
			setFilterBondData(arr);
			setCountBond(prev => prev + 1);
		}
	})
	if (node) observerBond.current.observe(node);
  });

  const findLastElementKeyForMF = useCallback(node  => {
	if (observerMF.current) observerMF.current.disconnect();
	observerMF.current = new IntersectionObserver(entries => {
		if (entries[0].isIntersecting) {
			const arr = newMFData.slice(0, 8 * (countMF + 1));
			setFilterMFData(arr);
			setCountMF(prev => prev + 1);
		}
	});
	if (node) observerMF.current.observe(node);
  });

  useEffect(() => {
	let arr = [];
	if (!isEmpty(bondData)) {
		Object.keys(bondData)?.map((key) => (
			arr = arr.concat(bondData[key])
		));
	}
	setNewBondData(arr);
	setFilterBondData(arr.splice(0, 8));
  }, [bondData]);

  useEffect(() => {
	let arr = [];
	if (!isEmpty(mFData)) {
		Object.keys(mFData)?.map((key) => (
			arr = arr.concat(mFData[key])
		));
	}
	setNewMFData(arr);
	setFilterMFData(arr.splice(0, 8));
  }, [mFData]);

  return (
	<Box>
		{
			bond && (
				<Box className={classes.bondContainer}>
					<Box style={{ display: 'flex', justifyContent: 'space-between' }}>
						<Typography className={classes.bondTitle}>
							High Demand Bonds
						</Typography>
						<Tooltip title="SEE MORE">
							<DoubleArrowIcon 
								className={classes.iconStyle}
								onClick={() => navigate(ROUTES.BONDS)}
								fontSize="large"
							/>
						</Tooltip>
					</Box>
					<Box className={classes.bondGridFormat}>
						{
							filterBondData.length && filterBondData.map((item, key) => (
								key + 1 === filterBondData.length ? 
									<div ref={findLastElementKeyForBond} key={key}><BoxContainer _bondData={item} /></div> 
									:  <BoxContainer key={key} _bondData={item} />
							))
						}
					</Box>
					{
						!filterBondData.length &&
						(
							<Box>
								<Box style={{ display: 'flex', justifyContent: 'center' }}>
									<WarningAmberIcon style={{ width: '120px', height: '120px' }} fontSize='100px' color='warning' />
								</Box>
								<Box style={{ display: 'flex', justifyContent: 'center' }}>
									<CircularProgress style={{ margin: 'auto', marginTop: '2rem', textAlign: 'center' }} color="secondary" />
								</Box>
								<Typography style={{ fontSize: '2rem', textAlign: 'center', marginTop: '2rem', fontWeight: 700, color: '#FFF' }}>
									Fetching Bond Details
								</Typography>
							</Box>
						)
					}
				</Box>
			)
		}
		{
			mf && (
				<Box style={{ paddingBottom: "7rem", background: "#8FDDE7" }} className={classes.bondContainer}>
					<Box style={{ display: 'flex', justifyContent: 'space-between' }}>
						<Typography style={{ color: "#282828" }} className={classes.bondTitle}>
							Mutual Funds in Spotlight
						</Typography>
						<Tooltip title="SEE MORE">
							<DoubleArrowIcon 
								style={{ color: "#0000FF" }}
								className={classes.iconStyle}
								onClick={() => navigate(ROUTES.MUTUAL_FUNDS)}
								fontSize="large"
							/>
						</Tooltip>
					</Box>
					<Box className={classes.bondGridFormat}>
						{
							filterMFData.length > 0 && filterMFData.map((item, key) => (
								key + 1 === filterMFData.length ? 
									<div ref={findLastElementKeyForMF} key={key}><BoxContainer _mFData={item} /></div> 
									:  <BoxContainer key={key} _mFData={item} />
							))
						}
					</Box>
					{
						!filterMFData.length &&
						(
							<Box>
								<Box style={{ display: 'flex', justifyContent: 'center' }}>
									<WarningAmberIcon style={{ width: '120px', height: '120px' }} fontSize='100px' color='warning' />
								</Box>
								<Box style={{ display: 'flex', justifyContent: 'center' }}>
									<CircularProgress style={{ margin: 'auto', marginTop: '2rem', textAlign: 'center' }} color="secondary" />
								</Box>
								<Typography style={{ fontSize: '2rem', textAlign: 'center', marginTop: '2rem', fontWeight: 700, color: '#FFF' }}>
									Fetching Mutual Funds Details
								</Typography>
							</Box>
						)
					}
				</Box>
			)
		}
		{
			unlisted && (
				<Box style={{ background: "#EAEFF2" }} className={classes.bondContainer}>
					<Box style={{ display: 'flex', justifyContent: 'space-between' }}>
						<Typography style={{ color: "#282828" }} className={classes.bondTitle}>
							Unlisted Stocks
						</Typography>
						<Tooltip title="SEE MORE">
							<DoubleArrowIcon 
								style={{ color: "#0000FF" }}
								className={classes.iconStyle}
								onClick={() => navigate(ROUTES.UNLISTED_STOCKS)}
								fontSize="large"
							/>
						</Tooltip>
					</Box>
					{
						!isEmpty(unlistedData) && (
							<BoxContainer _unlistedData={unlistedData} />
						)
					}
					{
						isEmpty(unlistedData) &&
						(
							<Box>
								<Box style={{ display: 'flex', justifyContent: 'center' }}>
									<WarningAmberIcon style={{ width: '120px', height: '120px' }} fontSize='100px' color='warning' />
								</Box>
								<Box style={{ display: 'flex', justifyContent: 'center' }}>
									<CircularProgress style={{ margin: 'auto', marginTop: '2rem', textAlign: 'center' }} color="secondary" />
								</Box>
								<Typography style={{ fontSize: '2rem', textAlign: 'center', marginTop: '2rem', fontWeight: 700, color: '#000' }}>
									Fetching Unlisted Stocks Details
								</Typography>
							</Box>
						)
					}
				</Box>
			)
		}
		{
			learnAboutBondsData && (
				<Box className={classes.learnContainer}>
					<Typography style={{ color: '#282828' }} className={classes.bondTitle}>
						Learn About Bonds
					</Typography>
					<Box className={classes.bondGridFormat}>
						{
							learnAboutBondsData && learnAboutBondsData.map((item) => (
								<BoxContainer _learnAboutBondsData={item} />
							))
						}
					</Box>
				</Box>
			)
		}
		{
			meetOurTeam && (
				<Box style={{ backgroundColor: '#f5f5f5', height: '27rem' }} className={classes.learnContainer}>
					<Typography style={{ color: '#282828' }} className={classes.bondTitle}>
						Meet Our Team
					</Typography>
					<Box className={classes.bondGridFormat}>
						{
							meetOurTeam && meetOurTeam.map((item) => (
								<BoxContainer _meetOurTeam={item} />
							))
						}
					</Box>
				</Box>
			)
		}
	</Box>
  )
}

export default BoxSection;
